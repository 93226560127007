/** Theme Action (state.theme) */

const SET_ENABLE_MOBILE_MENU = 'SET_ENABLE_MOBILE_MENU';
const SET_ENABLE_CLOSED_SIDEBAR = 'SET_ENABLE_CLOSED_SIDEBAR';


/** LWA (Login with Amazon) Actions (state.LWA) */

const AMZN_LOGIN = 'AMZN_LOGIN';
const TRIGGER_AMZN_LOGOUT = 'TRIGGER_AMZN_LOGOUT';
const FINISH_AMZN_LOGOUT = 'FINISH_AMZN_LOGOUT';


/** Login Actions (state.loggedUser) */

const LOGIN_BEGIN = 'LOGIN_BEGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILED = 'LOGIN_FAILED';
const SET_DEFAULT_SELLER_ID = 'SET_DEFAULT_SELLER_ID';
const STORE_AUTH_SUCCESS = 'STORE_AUTH_SUCCESS';
const CONFIRM_TUTORIAL = 'CONFIRM_TUTORIAL';
const AUTH_ADVERTISING_API_SUCCESS = 'AUTHORIZE_ADVERTISING_API_SUCCESS';
const UPDATE_SUBSCRIPTION_LEVEL = 'UPDATE_SUBSCRIPTION_LEVEL';


/** Logout Actions (state.loggedUser) */

const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILED_NO_CONNECTION = 'LOGOUT_FAILED_NO_CONNECTION';
const LOGOUT_FAILED_UNKNOWN_ERROR = 'LOGOUT_FAILED_UNKNOWN_ERROR';


/** Registration Actions (state.loggedUser) */

const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
const REGISTRATION_FAILED_EXISTING_USER = 'REGISTRATION_FAILED_EXISTING_USER';
const REGISTRATION_FAILED_NO_CONNECTION = 'REGISTRATION_FAILED_NO_CONNECTION';
const REGISTRATION_FAILED_UNKNOWN_ERROR = 'REGISTRATION_FAILED_UNKNOWN_ERROR';


/** User Profile Actions (state.profile) */

const LOAD_DIRECTORIES_BEGIN = 'LOAD_DIRECTORIES_BEGIN';
const LOAD_DIRECTORIES_SUCCESS = 'LOAD_DIRECTORIES_SUCCESS';
const LOAD_DIRECTORIES_FAILED = 'LOAD_DIRECTORIES_FAILED';
const LOAD_STORES_DIRECTORIES_BEGIN = 'LOAD_STORES_DIRECTORIES_BEGIN';
const LOAD_STORES_DIRECTORIES_SUCCESS = 'LOAD_STORES_DIRECTORIES_SUCCESS';
const LOAD_STORES_DIRECTORIES_FAILED = 'LOAD_STORES_DIRECTORIES_FAILED';
export const LOAD_CONTENT_AGENCIES_BEGIN = 'LOAD_CONTENT_AGENCIES_BEGIN';
const LOAD_CONTENT_AGENCIES_SUCCESS = 'LOAD_CONTENT_AGENCIES_SUCCESS';
const LOAD_CONTENT_AGENCIES_FAILED = 'LOAD_CONTENT_AGENCIES_FAILED';
const LOAD_PROJECTS_BEGIN = 'LOAD_PROJECTS_BEGIN';
const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
const LOAD_PROJECTS_FAILED = 'LOAD_PROJECTS_FAILURE';


/** Shopping cart Actions (state.cart) */

const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const CLEAR_CART = 'CLEAR_CART';

// [OLD] (state.userCampaigns)
const LOAD_CAMPAIGN_LIST_BEGIN = 'LOAD_CAMPAIGN_LIST_BEGIN';
const LOAD_CAMPAIGN_LIST_SUCCESS = 'LOAD_CAMPAIGN_LIST_SUCCESS';
const LOAD_CAMPAIGN_LIST_FAILED_NO_CONNECTION =
	'LOAD_CAMPAIGN_LIST_FAILED_NO_CONNECTION';
const LOAD_CAMPAIGN_LIST_FAILED_UNKNOWN_ERROR =
	'LOAD_CAMPAIGN_LIST_FAILED_UNKNOWN_ERROR';


/** Seller Profiles Actions (state.accountOverview.landing) */

const LOAD_ADPROFILES_BEGIN = 'LOAD_ADPROFILES_BEGIN';
const LOAD_ADPROFILES_SUCCESS = 'LOAD_ADPROFILES_SUCCESS';
const LOAD_ADPROFILES_FAILED = 'LOAD_ADPROFILES_FAILED';
const LOAD_READY_CAMPAIGNS_BEGIN = 'LOAD_READY_CAMPAIGNS_BEGIN';
const LOAD_READY_CAMPAIGNS_SUCCESS = 'LOAD_READY_CAMPAIGNS_SUCCESS';
const LOAD_READY_CAMPAIGNS_FAILED = 'LOAD_READY_CAMPAIGNS_FAILED';


/** Store Overview Actions (state.accountOverview.store) */

const LOAD_STORE_DATA_BEGIN = 'LOAD_STORE_DATA_BEGIN';
const LOAD_STORE_DATA_SUCCESS = 'LOAD_STORE_DATA_SUCCESS';
const LOAD_STORE_DATA_FAILED = 'LOAD_STORE_DATA_FAILED';
const LOAD_STORE_UNITS_DATA_BEGIN = 'LOAD_STORE_UNITS_DATA_BEGIN';
const LOAD_STORE_UNITS_DATA_SUCCESS = 'LOAD_STORE_UNITS_DATA_SUCCESS';
const LOAD_STORE_UNITS_DATA_FAILED = 'LOAD_STORE_UNITS_DATA_FAILED';
const LOAD_STORE_SALES_DATA_BEGIN = 'LOAD_STORE_SALES_DATA_BEGIN';
const LOAD_STORE_SALES_DATA_SUCCESS = 'LOAD_STORE_SALES_DATA_SUCCESS';
const LOAD_STORE_SALES_DATA_FAILED = 'LOAD_STORE_SALES_DATA_FAILED';
const LOAD_STORE_AD_DATA_BEGIN = 'LOAD_STORE_AD_DATA_BEGIN';
const LOAD_STORE_AD_DATA_SUCCESS = 'LOAD_STORE_AD_DATA_SUCCESS';
const LOAD_STORE_AD_DATA_FAILED = 'LOAD_STORE_AD_DATA_FAILED';

// Search Products
const LOAD_ALL_USER_PRODUCTS_BEGIN = 'LOAD_ALL_USER_PRODUCTS_BEGIN';
const LOAD_ALL_USER_PRODUCTS_SUCCESS = 'LOAD_ALL_USER_PRODUCTS_SUCCESS';
const LOAD_ALL_USER_PRODUCTS_FAILED = 'LOAD_ALL_USER_PRODUCTS_FAILED';


/** Product Overview Actions (state.accountOverview.product) */

const LOAD_PRODUCT_DATA_BEGIN = 'LOAD_PRODUCT_DATA_BEGIN';
const LOAD_PRODUCT_DATA_SUCCESS = 'LOAD_PRODUCT_DATA_SUCCESS';
const LOAD_PRODUCT_DATA_FAILED = 'LOAD_PRODUCT_DATA_FAILED';
const LOAD_PRODUCT_PERFORMANCE_BEGIN = 'LOAD_PRODUCT_PERFORMANCE_BEGIN';
const LOAD_PRODUCT_PERFORMANCE_SUCCESS = 'LOAD_PRODUCT_PERFORMANCE_SUCCESS';
const LOAD_PRODUCT_PERFORMANCE_FAILED = 'LOAD_PRODUCT_PERFORMANCE_FAILED';
const LOAD_PRODUCT_MRP_DATA_BEGIN = 'LOAD_PRODUCT_MRP_DATA_BEGIN';
const LOAD_PRODUCT_MRP_DATA_SUCCESS = 'LOAD_PRODUCT_MRP_DATA_SUCCESS';
const LOAD_PRODUCT_MRP_DATA_FAILED = 'LOAD_PRODUCT_MRP_DATA_FAILED';
const LOAD_PARENT_PRODUCT_RANK_DATA_BEGIN = 'LOAD_PARENT_PRODUCT_RANK_DATA_BEGIN';
const LOAD_PARENT_PRODUCT_RANK_DATA_SUCCESS = 'LOAD_PARENT_PRODUCT_RANK_DATA_SUCCESS';
const LOAD_PARENT_PRODUCT_RANK_DATA_FAILED = 'LOAD_PARENT_PRODUCT_RANK_DATA_FAILED';
const LOAD_PRODUCT_BLEEDER_KW_BEGIN = 'LOAD_PRODUCT_BLEEDER_KW_BEGIN';
const LOAD_PRODUCT_BLEEDER_KW_SUCCESS = 'LOAD_PRODUCT_BLEEDER_KW_SUCCESS';
const LOAD_PRODUCT_BLEEDER_KW_FAILED = 'LOAD_PRODUCT_BLEEDER_KW_FAILED';
const LOAD_PRODUCT_IDLE_KW_BEGIN = 'LOAD_PRODUCT_IDLE_KW_BEGIN';
const LOAD_PRODUCT_IDLE_KW_SUCCESS = 'LOAD_PRODUCT_IDLE_KW_SUCCESS';
const LOAD_PRODUCT_IDLE_KW_FAILED = 'LOAD_PRODUCT_IDLE_KW_FAILED';


/** Campaign Overview Actions (state.accountOverview.campaign) */

const LOAD_CAMPAIGN_DATA_BEGIN = 'LOAD_CAMPAIGN_DATA_BEGIN';
const LOAD_CAMPAIGN_DATA_SUCCESS = 'LOAD_CAMPAIGN_DATA_SUCCESS';
const LOAD_CAMPAIGN_DATA_FAILED = 'LOAD_CAMPAIGN_DATA_FAILED';

const KEYWORD_UPDATE_BEGIN = 'KEYWORD_UPDATE_BEGIN';
const KEYWORD_UPDATE_SUCCESS = 'KEYWORD_UPDATE_SUCCESS';
const KEYWORD_UPDATE_FAILED = 'KEYWORD_UPDATE_FAILED';


/** Market Analysis Actions (state.marketAnalysis) */

const ADD_ASIN_FOR_MA = 'ADD_ASIN_FOR_MA';
const SEND_ASINS_FOR_MA = 'SEND_ASINS_FOR_MA';
const LOAD_ASIN_MA_DATA = 'LOAD_ASIN_MA_DATA';
const LOAD_MA_DATA_COMPLETE = 'LOAD_MA_DATA_COMPLETE';
const REMOVE_MA = 'REMOVE_MA';
const SET_AUTOFILL_ASIN = 'SET_AUTOFILL_ASIN';


/** Campaign Builder Actions (state.campaignBuilder) */

const SET_AUTOFILL_FIELD = 'SET_AUTOFILL_FIELD';
const RESET_AUTOFILL_FIELD = 'RESET_AUTOFILL_FIELD';


/** Launchpad Actions (no associated state) */

const LAUNCH_CAMPAIGN_BEGIN = 'LAUNCH_CAMPAIGN_BEGIN';
const LAUNCH_CAMPAIGN_SUCCESS = 'LAUNCH_CAMPAIGN_SUCCESS';
const LAUNCH_CAMPAIGN_FAILED = 'LAUNCH_CAMPAIGN_FAILED';


/** Credits System Payments */

const CHECK_CREDITS_BALANCE = 'CHECK_CREDITS_BALANCE';
const PROMO_CODE_SUBMIT = 'PROMO_CODE_SUBMIT';

// Project Management
const ADD_PROJECT = 'ADD_PROJECT';
const VIEW_PROJECTS = 'VIEW_PROJECT';
const PROJECT_LOADING = 'PROJECT_LOADING';
const GET_STORE_DETAILS = 'GET_STORE_DETAILS';
const INITIAL_STORE = 'INITIAL_STORE';
const SET_CURRENT_STORE = 'SET_CURRENT_STORE';
const SET_PROJECT_LOADING = 'SET_PROJECT_LOADING';
const SET_PROJECT_API_STATUS = 'SET_PROJECT_API_STATUS';
const SET_SUBMIT_PROJECT_LOADING = 'SET_SUBMIT_PROJECT_LOADING';
const SET_STORE_PROJECTS = 'SET_STORE_PROJECTS';
const SET_CURRENT_STORE_ONLY = 'SET_CURRENT_STORE_ONLY';


//Lead Generation
const LOAD_PRODUCT_CATEGORIES_BEGIN = 'LOAD_PRODUCT_CATEGORIES_BEGIN';
const LOAD_PRODUCT_CATEGORIES_SUCCESS = 'LOAD_PRODUCT_CATEGORIES_SUCCESS';
const LOAD_PRODUCT_CATEGORIES_FAILED = 'LOAD_PRODUCT_CATEGORIES_FAILED';
const LOAD_CATEGORY_PRODUCTS_SELLERS_BEGIN = 'LOAD_CATEGORY_PRODUCTS_SELLERS_BEGIN';
const LOAD_CATEGORY_PRODUCTS_SELLERS_SUCCESS = 'LOAD_CATEGORY_PRODUCTS_SELLERS_SUCCESS';
const LOAD_CATEGORY_PRODUCTS_SELLERS_FAILED = 'LOAD_CATEGORY_PRODUCTS_SELLERS_FAILED';
const LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_BEGIN = 'LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_BEGIN';
const LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_SUCCESS = 'LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_SUCCESS';
const LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED = 'LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED';
const LOAD_LEAD_GEN_JOBS_BEGIN = 'LOAD_LEAD_GEN_JOBS_BEGIN';
const LOAD_LEAD_GEN_JOBS_SUCCESS = 'LOAD_LEAD_GEN_JOBS_SUCCESS';
const LOAD_LEAD_GEN_JOBS_FAILED = 'LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED';
const LOAD_AMAZON_PRODUCT_CATEGORIES_BEGIN = 'LOAD_Amazon_PRODUCT_CATEGORIES_BEGIN';
const LOAD_AMAZON_PRODUCT_CATEGORIES_SUCCESS = 'LOAD_Amazon_PRODUCT_CATEGORIES_SUCCESS';
const LOAD_AMAZON_PRODUCT_CATEGORIES_FAILED = 'LOAD_Amazon_PRODUCT_CATEGORIES_FAILED';
const LOAD_AMAZON_DOMAINS_BEGIN = 'LOAD_AMAZON_DOMAINS_BEGIN';
const LOAD_AMAZON_DOMAINS_SUCCESS = 'LOAD_AMAZON_DOMAINS_SUCCESS';
const LOAD_AMAZON_DOMAINS_FAILED = 'LOAD_AMAZON_DOMAINS_FAILED';
const LOAD_APPSUMO_PAYEMENT_DETAILS_BEGIN  ='LOAD_APPSUMO_PAYEMENT_DETAILS_BEGIN';
const LOAD_APPSUMO_PAYEMENT_DETAILS_SUCCESS='LOAD_APPSUMO_PAYEMENT_DETAILS_SUCCESS';
const LOAD_APPSUMO_PAYEMENT_DETAILS_FAILED ='LOAD_APPSUMO_PAYEMENT_DETAILS_FAILED';
const LOAD_SELLER_PARTNER_LIST_BEGIN = 'LOAD_SELLER_PARTNER_LIST_BEGIN';
const LOAD_SELLER_PARTNER_LIST_SUCCESS = 'LOAD_SELLER_PARTNER_LIST_SUCCESS';
const LOAD_SELLER_PARTNER_LIST_FAILED = 'LOAD_SELLER_PARTNER_LIST_FAILED';


export default {
	SET_ENABLE_MOBILE_MENU,
	SET_ENABLE_CLOSED_SIDEBAR,

	AMZN_LOGIN,
	TRIGGER_AMZN_LOGOUT,
	FINISH_AMZN_LOGOUT,

	LOGIN_SUCCESS,
	LOGIN_BEGIN,
	LOGIN_FAILED,
	SET_DEFAULT_SELLER_ID,
	STORE_AUTH_SUCCESS,
	CONFIRM_TUTORIAL,
	AUTH_ADVERTISING_API_SUCCESS,
	UPDATE_SUBSCRIPTION_LEVEL,

	LOGOUT_BEGIN,
	LOGOUT_SUCCESS,
	LOGOUT_FAILED_NO_CONNECTION,
	LOGOUT_FAILED_UNKNOWN_ERROR,

	REGISTRATION_FAILED,
	REGISTRATION_FAILED_EXISTING_USER,
	REGISTRATION_FAILED_NO_CONNECTION,
	REGISTRATION_FAILED_UNKNOWN_ERROR,

	LOAD_DIRECTORIES_BEGIN,
	LOAD_DIRECTORIES_SUCCESS,
	LOAD_DIRECTORIES_FAILED,
	LOAD_STORES_DIRECTORIES_BEGIN,
	LOAD_STORES_DIRECTORIES_SUCCESS,
	LOAD_STORES_DIRECTORIES_FAILED,
	LOAD_CONTENT_AGENCIES_BEGIN,
	LOAD_CONTENT_AGENCIES_SUCCESS,
	LOAD_CONTENT_AGENCIES_FAILED,
	LOAD_PROJECTS_BEGIN,
	LOAD_PROJECTS_SUCCESS,
	LOAD_PROJECTS_FAILED,

	ADD_TO_CART,
	REMOVE_FROM_CART,
	CLEAR_CART,

	LOAD_CAMPAIGN_LIST_BEGIN,
	LOAD_CAMPAIGN_LIST_SUCCESS,
	LOAD_CAMPAIGN_LIST_FAILED_NO_CONNECTION,
	LOAD_CAMPAIGN_LIST_FAILED_UNKNOWN_ERROR,

	ADD_ASIN_FOR_MA,
	SEND_ASINS_FOR_MA,
	LOAD_ASIN_MA_DATA,
	LOAD_MA_DATA_COMPLETE,
	REMOVE_MA,
	SET_AUTOFILL_ASIN,

	SET_AUTOFILL_FIELD,
	RESET_AUTOFILL_FIELD,

	LOAD_ADPROFILES_BEGIN,
	LOAD_ADPROFILES_SUCCESS,
	LOAD_ADPROFILES_FAILED,

	LOAD_READY_CAMPAIGNS_BEGIN,
	LOAD_READY_CAMPAIGNS_SUCCESS,
	LOAD_READY_CAMPAIGNS_FAILED,

	LOAD_STORE_DATA_BEGIN,
	LOAD_STORE_DATA_SUCCESS,
	LOAD_STORE_DATA_FAILED,

	LOAD_STORE_UNITS_DATA_BEGIN,
	LOAD_STORE_UNITS_DATA_SUCCESS,
	LOAD_STORE_UNITS_DATA_FAILED,

	LOAD_STORE_SALES_DATA_BEGIN,
	LOAD_STORE_SALES_DATA_SUCCESS,
	LOAD_STORE_SALES_DATA_FAILED,

	LOAD_STORE_AD_DATA_BEGIN,
	LOAD_STORE_AD_DATA_SUCCESS,
	LOAD_STORE_AD_DATA_FAILED,

	LOAD_ALL_USER_PRODUCTS_BEGIN,
	LOAD_ALL_USER_PRODUCTS_SUCCESS,
	LOAD_ALL_USER_PRODUCTS_FAILED,

	LOAD_PRODUCT_DATA_BEGIN,
	LOAD_PRODUCT_DATA_SUCCESS,
	LOAD_PRODUCT_DATA_FAILED,

	LOAD_PRODUCT_PERFORMANCE_BEGIN,
	LOAD_PRODUCT_PERFORMANCE_SUCCESS,
	LOAD_PRODUCT_PERFORMANCE_FAILED,

	LOAD_PRODUCT_MRP_DATA_BEGIN,
	LOAD_PRODUCT_MRP_DATA_SUCCESS,
	LOAD_PRODUCT_MRP_DATA_FAILED,

	LOAD_PARENT_PRODUCT_RANK_DATA_BEGIN,
	LOAD_PARENT_PRODUCT_RANK_DATA_SUCCESS,
	LOAD_PARENT_PRODUCT_RANK_DATA_FAILED,

	LOAD_PRODUCT_BLEEDER_KW_BEGIN,
	LOAD_PRODUCT_BLEEDER_KW_SUCCESS,
	LOAD_PRODUCT_BLEEDER_KW_FAILED,

	LOAD_PRODUCT_IDLE_KW_BEGIN,
	LOAD_PRODUCT_IDLE_KW_SUCCESS,
	LOAD_PRODUCT_IDLE_KW_FAILED,

	LOAD_CAMPAIGN_DATA_BEGIN,
	LOAD_CAMPAIGN_DATA_SUCCESS,
	LOAD_CAMPAIGN_DATA_FAILED,

	KEYWORD_UPDATE_BEGIN,
	KEYWORD_UPDATE_SUCCESS,
	KEYWORD_UPDATE_FAILED,

	LAUNCH_CAMPAIGN_BEGIN,
	LAUNCH_CAMPAIGN_SUCCESS,
	LAUNCH_CAMPAIGN_FAILED,

	CHECK_CREDITS_BALANCE,
	PROMO_CODE_SUBMIT,

	ADD_PROJECT,
	VIEW_PROJECTS,
	PROJECT_LOADING,
	GET_STORE_DETAILS,
	INITIAL_STORE,
	SET_CURRENT_STORE,
	SET_PROJECT_LOADING,
	SET_PROJECT_API_STATUS,
	SET_SUBMIT_PROJECT_LOADING,
	SET_STORE_PROJECTS,
	SET_CURRENT_STORE_ONLY,

	LOAD_PRODUCT_CATEGORIES_BEGIN,
	LOAD_PRODUCT_CATEGORIES_SUCCESS,
	LOAD_PRODUCT_CATEGORIES_FAILED,
	LOAD_CATEGORY_PRODUCTS_SELLERS_BEGIN,
	LOAD_CATEGORY_PRODUCTS_SELLERS_SUCCESS,
	LOAD_CATEGORY_PRODUCTS_SELLERS_FAILED,
	LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_BEGIN,
	LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_SUCCESS,
	LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED,
	LOAD_LEAD_GEN_JOBS_BEGIN,
	LOAD_LEAD_GEN_JOBS_SUCCESS,
	LOAD_LEAD_GEN_JOBS_FAILED,
	LOAD_AMAZON_PRODUCT_CATEGORIES_BEGIN,
	LOAD_AMAZON_PRODUCT_CATEGORIES_SUCCESS,
	LOAD_AMAZON_PRODUCT_CATEGORIES_FAILED,
	LOAD_AMAZON_DOMAINS_BEGIN,
	LOAD_AMAZON_DOMAINS_SUCCESS,
	LOAD_AMAZON_DOMAINS_FAILED,
	LOAD_APPSUMO_PAYEMENT_DETAILS_BEGIN ,
    LOAD_APPSUMO_PAYEMENT_DETAILS_SUCCESS,
    LOAD_APPSUMO_PAYEMENT_DETAILS_FAILED,
	LOAD_SELLER_PARTNER_LIST_BEGIN,
	LOAD_SELLER_PARTNER_LIST_SUCCESS,
	LOAD_SELLER_PARTNER_LIST_FAILED,	
	
};
