import React, { useCallback, useEffect, useState } from "react";
import GetWorkflowMainCategoryService from "../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowMainCategoryService";
import { connect } from "react-redux";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import WorkflowPhasesPage from "./Components/WorkflowPhasesPage";
import { useHistory } from "react-router-dom";
import FetchAllTaskByUser from "../../../../../../Service/OPSServices/WorkflowsService/FetchAllTaskByUser";
import ReactTable from "react-table";
function WorkflowsMainPage(props) {
  const [selectedStore, setSelectedStore] = useState("");
  const [workflowMainCategory, setWorkflowMainCategory] = useState("");
  const [workflowPhases, setWorkflowPhases] = useState("");
  const [workflowPhasesList, setWorkflowPhasesList] = useState("");
  const [workflowPhasesPage, setWorkflowPhasesPage] = useState(false);
  const [selectedWorkflowMasterId, SetSelectedWorkflowMasterId] = useState();
  const [userTaskData, setUserTaskData] = useState([]);
  const [searchText, setSearchText]=useState('')
  const [selectedStoreData, setSelectedStoreData] = useState({
    integerId: "",
    storeName: "",
  });

  useEffect(() => {
    GetWorkflowMainCategoryService(props.token).then((res) => {
      if (res) {
        console.log(res);
        setWorkflowMainCategory(res.masterWorkflows);
        setWorkflowPhases(res.workflowPhases);
      }
    });
    FetchAllTaskByUser(props.token).then((res) => {
      if (res) {
        console.log(res);
        setUserTaskData(res.taskList)
      }
    });
  }, []);

  const handleStoreChange = (e) => {
    console.log(e.target.value);
    props.adProfiles.map((store) => {
      if (e.target.value == store.integerID) {
        setSelectedStore(e.target.value);
        console.log(e.target.value)
        setSelectedStoreData({
          integerId: store.integerID,
          storeName: store.name
        });
      }
    });
  };
  const handleWorkflowMaster = (workflowId) => {
    SetSelectedWorkflowMasterId(workflowId);
    const filteredPhases = workflowPhases && workflowPhases.filter(phase => phase.WorkflowsMaster == workflowId).sort((a, b) => a.id - b.id);
    setWorkflowPhasesList(filteredPhases)
  };

  const handleBackButtonToWorkflow = () => {
    setWorkflowPhasesPage(false);
    SetSelectedWorkflowMasterId(null)
    setSelectedStore({integerID:''})
    setSelectedStoreData(null)
  };
  const quickOpenWorkflowByStoreName = (data) =>{
    setSelectedStoreData({integerId:data.integerId,storeName:data.storeName})
    handleWorkflowMaster(data.workflowId)
    setWorkflowPhasesPage(true)
  }
  const handaleSearchStoreHealth=(e)=>{
    setSearchText(e.target.value)
  }
  const filterStorehealth= userTaskData.filter((row)=>{
    const storeName =( row.storeName ||' ').toLowerCase();
    const workFlow =(row.workFlow ||'').toLowerCase();
    const phase = (row.phase || '').toLowerCase();
    const task =(row.task ||'').toLowerCase();
    const status =(row.status ||'').toLowerCase();
    return storeName.includes(searchText.toLowerCase())||workFlow.includes(searchText.toLowerCase())||phase.includes(searchText.toLowerCase())||task.includes(searchText.toLowerCase())||status.includes(searchText.toLowerCase());
    // return storeName.includes(searchText.toLowerCase())|| workFlow.includes(searchText.toLowerCase())|| phase.includes(searchText.toLowerCase())||task.includes(toLowerCase())||status.includes(toLowerCase())
  });
   
  const columns=[
    {
      Header:' Store Name',
      accessor:'storeName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={()=>{quickOpenWorkflowByStoreName(cellInfo.original)}}>
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Worflow Name',
      accessor:'workflow',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Phase',
      accessor:'phase',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Tasks',
      accessor:'task'
    },
    {
      Header:'Status',
      accessor:'status',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    }

  ]
  return (
    <>
      <Container>
        {!workflowPhasesPage ? (
          <Row>
            <Col md={12}>
              <Card className=" p-4 card-of-problemarea">
                <Row>
                  <Col md={6}>
                    <select
                      id="StoreList"
                      name="StoreList"
                      onChange={(e) => handleStoreChange(e)}
                      value={selectedStore.integerID}
                      className="form-control"
                    >
                      <option value="">--Select Store--</option>
                      {props.adProfiles.map((store) => (
                        <option value={store.integerID}> {store.name}</option>
                      ))}
                    </select>
                  </Col>
                  <Col md={6}>
                    <select
                      onChange={(e) => handleWorkflowMaster(e.target.value)}
                      value={selectedWorkflowMasterId}
                      className="form-control"
                    >
                      <option value="">--Select Workflow--</option>
                      {workflowMainCategory &&
                        workflowMainCategory.map((wf) => (
                          // <option value={wf.id} > {wf.name}</option>
                          <option value={wf.id} disabled={!selectedStore || !selectedStoreData}> {wf.name}</option>
                        ))}
                    </select>
                  </Col>

                  <Col md={12}>
                    <p className="text-center mt-3">
                      <Button
                        className="btn-background-color"
                        onClick={() => setWorkflowPhasesPage(true)}
                        disabled={!selectedWorkflowMasterId || !(selectedStore, selectedStoreData )}
                      >
                        Submit
                      </Button>
                    </p>
                  </Col>
                </Row>
              </Card>
              <Card className=" p-4 mt-4 card-of-problemarea">
              <Input
                  type='text'
                  className='w-25 mb-3'
                  placeholder="Search Text"
                  onChange={(e)=>handaleSearchStoreHealth(e)}/>
              <ReactTable
                data={filterStorehealth}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={50}
                noDataText={"No Data Found."}
                />
              </Card>
            </Col>
          </Row>
        ) : (
          <WorkflowPhasesPage
            handleBackButtonToWorkflow={handleBackButtonToWorkflow}
            selectedWorkflowMasterId={selectedWorkflowMasterId}
            workflowPhases={workflowPhasesList}
            workflowMainCategory={workflowMainCategory}
            selectedStoreData={selectedStoreData}
          />
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(WorkflowsMainPage);
