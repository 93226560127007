import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
import { Button, Card, Col, Input, Label, Row, Container,Form} from "reactstrap";
import { connect } from 'react-redux';
import FetchUsersForStores
    from "../../../../../../../Service/DashboardServices/UserProfileServices/FetchUsersForStores";
import FetchProductsForStores
    from "../../../../../../../Service/DashboardServices/UserProfileServices/FetchProductsForStores";
import UpdateStoreProducts
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UpdateStoreProducts";
import UpdateStoreProfile
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UpdateStoreProfile";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import RemoveProductService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RemoveProductService";
import SaveReportForStoreService from
"../../../../../../../Service/DashboardServices/UserProfileServices/SaveReportForStoreService"
import RequestReportForStoreService from
"../../../../../../../Service/DashboardServices/UserProfileServices/RequestReportForStoreService"
import UpdateSKUForAsinStoreService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService"   
import { RetryErrorReportV3Service } 
    from '../../../../../../../Service/OPSServices/RetryErrorReportV3Service';
import UpdateStoreManagementStatusService from '../../../../../../../Service/DashboardServices/UserProfileServices/UpdateStoreManagementStatusService';
    import {
        DropdownItem,
        DropdownMenu,
        DropdownToggle,
        UncontrolledButtonDropdown
    } from "reactstrap";
import { GetHistSPAPIDataTaskService } from '../../../../../../../Service/OPSServices/GetHistSPAPIDataTaskService';
import { GetMerchantListingsService } from    '../../../../../../../Service/OPSServices/GetMerchantListingsService';
import {GetCatalogueItemDetailsService } from '../../../../../../../Service/OPSServices/GetCatalogueItemDetailsService';
import {FetchErrorReportsV3Service}  from '../../../../../../../Service/OPSServices/FetchErrorReportsV3Service';
import {UpdateAllAsinSkusTaskService}  from '../../../../../../../Service/OPSServices/UpdateAllAsinSkusTaskService';
import {FetchPendingReportsV3Service} from '../../../../../../../Service/OPSServices/FetchPendingReportsV3Service' 
import FetchProductManagementHistory from '../../../../../../../Service/DashboardServices/UserProfileServices/FetchProductManagementHistory';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';  
import DownloadManagedAsinsServices from '../../../../../../../Service/DashboardServices/UserProfileServices/DownloadManagedAsinsServices';
import notify from '../../../../../../../../src/Notifications/ToastifyActions'
import UpdateStoreVisibilityService from '../../../../../../../Service/DashboardServices/UserProfileServices/UpdateStoreVisibilityService';
import { RequestHistDataAdvReportsTaskService } from '../../../../../../../Service/OPSServices/RequestHistDataAdvReportsTaskService';
function StoresDirectoryTable(props) {
    const [storeUsers, setStoreUsers] = useState();
    const [isStoresOpen, setIsStoresOpen] = useState(false);
    const [selectedStore , setSelectedStore] = useState();
    const [selectedIntegerID , setselectedIntegerID] = useState();
    const [requestDaysPopup, setRequestDaysPopup] = useState(false);
    const [SPAPIReportsPopup,setSPAPIReportsPopup] = useState(false); 
    const [SPAPIStoresReportsPopup,setSPAPIStoresReportsPopup] = useState(false); 
    const [days,setDays]=useState(0);
    const [integerID,setIntegerID]=useState();
    const [stringID,setStringID]=useState();
    const [requestSubmit,setRequestSubmit]=useState(false);
    const [isStoresAsinOpen, setIsStoresAsinOpen] = useState(false);
    const [managedProducts, setManagedProducts] = useState();
    const [jsonData, setJsonData] = useState();
    const [isJsonOpen, setIsJsonOpen] = useState();
    const [parsedJsonData, setParsedJsonData] = useState();
    const [errorReports,setErrorReports]= useState([]);
    const [errorReportlistOpen, setErrorReportlistOpen] = useState(false);
    const [PendingReports,setPendingReports]= useState([]);
    const [sPAPIReportlistOpen, setPendingReportlistOpen] = useState(false);
    const [managementHistory, setManagementHistory] = useState();
    const [managementHistoryOpen,setManagementHistoryOpen]= useState(false);
    const [storeDays, SetStoreDays]= useState();
    const [selectedUser, setSelectedUser] = useState({
        name: '',
        email: '',
    });

    const [stores , setStores] = useState(props.allStoresData);
    // const [selectedStore , setSelectedStore] = useState();

    const quickActions = [
        {
            name: () =>'Request Adv Report',
            action: ({integerID}) => {
                setIntegerID(integerID)
                setRequestDaysPopup(true)
                // handleRequestReports(integerID);
            }
        },
        {
            name: () => 'Save Adv Reports',
            action: ({integerID}) => {
                handleSaveReports(integerID);   
            }
        },
        {
            name: () =>'Retry Adv Reports',
            action: ({integerID}) => {
                // console.log('Retry surya');
                // console.log(integerID);
                RetryErrorReportV3(integerID); 
            }
        },
        {
            name: () =>'Fetch SPAPI Asin Sales',
            action: ({stringID}) => {
                setStringID(stringID); 
                setSPAPIReportsPopup(true)
            }
        },
        {
            name: () =>'Fetch SPAPI Stores Sales',
            action: ({stringID}) => {
                setStringID(stringID); 
                setSPAPIStoresReportsPopup(true)
            }
        },
        {
            name: () =>'Get Merchant All Listing',
            action: ({stringID}) => {
                getMerchantList(stringID); 
            }
        },
        
        {
            name: () =>'Display Errors Reports',
            action: ({integerID}) => {
                fetchErrorReports(integerID); 
            }
        },
        {
            name: () =>'Display Pending Reports',
            action: ({integerID}) => {
                displayPendingReports(integerID)
            }
        },
    ]
    const productQuickActions = [
        {
            name: () =>'View',
            action: ({integerID,name,email}) => {
                setIsStoresAsinOpen(!isStoresAsinOpen)
                setSelectedUser({name:name, email:email});
                handleFetchProductsForStores(integerID,name);                        
            }
        },
        {
            name: () => 'Update',
            action: ({integerID}) => {
                    handleUpdateProducts(integerID) 
            }
        },
        {
            name: () =>'Update Skus',
            action: ({integerID}) => {
                updateSKUs(integerID); 
            }
        },
        {
            name: () =>'Get Catalogue Item Details',
            action: ({stringID}) => {
                getCatalogueItemDetails(stringID); 
            }
        },
        {
            name: () => 'Management History',
            action: ({integerID}) => {
                setIntegerID(integerID)
                fetchProductManagementHistory(integerID) 
            }
        },
        {
            name: () => 'Download Managed Asins',
            action: ({integerID,name}) => {
                setIntegerID(integerID)
                downloadManagedAsins(integerID,name) 
            }
        }
        // {
        //     name: () => ' Download History',
        //     action: ({integerID}) => {
        //         exportProductManagementHistoryCSV(integerID) 
        //     }
        // }

    ]
    const exportToCSV = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: {'Product management history':ws }, SheetNames: ['Product management history'] };                
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, fileName + fileExtension);   
    };
    const exportToCSVManagedProducts = (csvData, fileName) => {
        if(!csvData){
            notify.NotifySuccess('Asin management data is not available for this store.')
            return true
          }
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: {'Managed Products':ws }, SheetNames: ['Managed Products'] };                
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, fileName + fileExtension);   
    };
const exportProductManagementHistoryCSV = async(integerID) => {
        const fileName = 'Product management history'
        let data = {
            asin:'',
            integerId:integerID
        } 
        let response = await FetchProductManagementHistory(props.token, data); 
        if(response)
        {
            exportToCSV(response, fileName);
        }
        } 

    const productQuickActionsDropdown = actions => (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret color="link">
                Actions
            </DropdownToggle>
            <DropdownMenu>
                {
                    productQuickActions.map(qa => (
                        <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>{qa.name(actions)}</DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
    const quickActionsDropdown = actions => (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret color="link">
                View
            </DropdownToggle>
            <DropdownMenu>
                {
                    quickActions.map(qa => (
                        <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>{qa.name(actions)}</DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
    
    const  handleFetchUsersForStores = async (profileId,storeName) => {
        console.log('in handleFetchUsersForStores') 
        setSelectedStore(storeName)
        let response = await FetchUsersForStores(props.token, profileId);  
        console.log (response)  
        if (response) {
            setStoreUsers(response);  }   
    }
    const  handleFetchProductsForStores = async (profileId,storeName) => {
        console.log('in handleFetchProductsForStores') 
        console.log(storeName) 
        setSelectedStore(storeName)
        setselectedIntegerID(profileId)
        let response = await FetchProductsForStores(props.token, profileId);  
        console.log (response)  
        if (response) {
                setManagedProducts(response['managedProducts'])  } 

    }
    const  handleUpdateProducts = async (profileId) => {
        console.log('in handleUpdateProducts') 
        let response = await UpdateStoreProducts(props.token, profileId);  
        console.log (response)   
    }
    const  fetchProductManagementHistory = async (integerID) => {
        console.log('in handleUpdateProducts')
        let data = {
            asin:'',
            integerId:integerID
        } 
        let response = await FetchProductManagementHistory(props.token, data); 
        if(response)
        {
            console.log (response)   
            setManagementHistory(response)
            setManagementHistoryOpen(true)
        }
    }
    const downloadManagedAsins = async (integerID,name) =>{
        let fileName = name+'_Managed_Asin_List_'+new Date()
        let data = {
            integerId:integerID
        } 
        let response = await DownloadManagedAsinsServices(props.token, data); 
        if(response)
        {
            console.log (response) 
            exportToCSVManagedProducts(response.productsList, fileName)  
        }
    }
    const handleRemoveProduct = async (asin,profileId) => {
        let response = await RemoveProductService(props.token, asin, profileId);   
        if(response)
        {
            console.log(selectedIntegerID)
            handleFetchProductsForStores(selectedIntegerID,selectedStore)
        }   
    };
    const fetchSPAPIReports= async ()=>{  
        console.log('in fetchSPAPIReports') 
        let data = {id : stringID ,days: days}
        let response = await GetHistSPAPIDataTaskService(props.token,data);  
        console.log (response) 
        if(response)
        {
            setDays('')
            setSPAPIReportsPopup(false)
        }
    }
    const fetchSPAPIStoreReports= async ()=>{  
        console.log('in fetchSPAPIReports') 
        let data = {id : stringID ,days: storeDays}
        let response = await RequestHistDataAdvReportsTaskService(props.token,data);  
        console.log (response) 
        if(response)
        {
            SetStoreDays('')
            console.log(response)
        }
        setSPAPIStoresReportsPopup(false)
    }

    const getMerchantList=async(stringID)=>{
        let data = {id : stringID}
        let response = await GetMerchantListingsService(props.token,data);  
        if(response)
        {
            console.log(response)   
        }
    }
    const updateSKUs=async()=>{
        let data = {id : integerID}
        let response = await UpdateAllAsinSkusTaskService(props.token,data);  
        if(response)
        {
            console.log(response)   
        }
    }
    const getCatalogueItemDetails=async(sellerPartnerID)=>{
        let data = {id : sellerPartnerID}
        let response = await GetCatalogueItemDetailsService(props.token,data);  
        if(response)
        {
            console.log(response)   
        }
    }
    const fetchErrorReports=async(integerID)=>{
        let data = {id : integerID}
        let response = await FetchErrorReportsV3Service(props.token,data);  
        if(response)
        {
            setErrorReports(response.allReports)
            setErrorReportlistOpen(true)
            console.log(response)   
        }
    }
    const displayPendingReports=async(integerID)=>{
        let data = {id : integerID}
        let response = await FetchPendingReportsV3Service(props.token,data);  
        if(response)
        {
            // setPendingReports(response.allReports)
            // setPendingReportlistOpen(true)
            setErrorReports(response.allReports)
            setErrorReportlistOpen(true)
            console.log(response)   
        }
    }
    const  handleUpdateStoreManagementStatus = async (profileId, isManaged) => {
        console.log('in handleUpdateStoreManagement') 
        let response = await UpdateStoreManagementStatusService(props.token, profileId,isManaged);  
        console.log (response)   
    }
    const  handleRequestReports = async (days,profileId) => {
        console.log('in handleRequestReports') 
        let response = await RequestReportForStoreService(props.token,days, profileId);  
        console.log (response)   
        if(response)
        {
            setDays('')
            setRequestDaysPopup(false)
        }
    };
    const  handleSaveReports = async (profileId) => {
        console.log('in handleRequestReports') 
        let response = await SaveReportForStoreService(props.token, profileId);  
        console.log (response)   
    };

    const  handleUpdateSkus = async (asin,profileId) => {
        console.log('in handleUpdateSkus');   
       
        let response = await UpdateSKUForAsinStoreService(props.token, asin,profileId);  
        console.log (response);   
        if(response)
        {
            console.log(selectedIntegerID)
            handleFetchProductsForStores(selectedIntegerID,selectedStore)
        }   
    
    };

    const RetryErrorReportV3 = (profileId) => {         

        let data = 
        {profileId : profileId}
        
        RetryErrorReportV3Service(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                console.log(advertisingDataStatus);               
            }
        })
    };
    const printTheJSONInPrettyFormat = (inputJson) => {

        var parseJSON = JSON.parse(inputJson);
  
        var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
        setParsedJsonData(parseJSON);
        setJsonData(JSONInPrettyFormat);
  
      };
      const changeVisibility = (flag,integerID) =>{
        console.log('changeVisibility')
        console.log(flag)
        let data = {
            integerID:integerID,
            visibilityState:flag
        }
        UpdateStoreVisibilityService(props.token,data).then((res)=>{
            if(res)
            {
                console.log(res)
            }
        })
      }
    const columns = [
                

        {
            Header: 'Creation Date',
            accessor: 'Report Date',
        },

        {
            Header: 'Report Status',
            accessor: 'ReportStatus',
            Cell: cellInfo => (               
                <Button
                    className="m-auto"
                    color={"link"}
                    onClick={() => {                        
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
 
        {
            Header: 'Store Name',
            accessor: 'StoreName',
        },
        
        {
            Header: 'ProfileId',
            accessor: 'ProfileId',
        },
     
    ];
   
    const outerColumns = [
        {
            Header: 'Profile Id',
            accessor: 'integerID',
            width: 135,
        },        
        {
            Header: 'Store',
            accessor: 'name',
            width: 100,
        },
        {
            Header: 'Country',
            accessor: 'location',
            Cell: (row) => {
                return <p style={{ marginLeft: '45%'}}>{row.value}</p>;
              }
        }, 
        {
            Header: 'Is Managed ',
            accessor: 'isManaged',
            Cell: props => (     
                props.original.isManaged ? <p style={{ marginLeft: '45%'}}>Yes</p> : <p style={{ marginLeft: '45%',padding: '5px',backgroundColor: "#ff4761",color:"white"}}>No</p> 
            ) 
        }, 
        {
            Header: 'Adv Auth',
            accessor: 'advertiserProfile',
            Cell: props => (     
                props.original.advertiserProfile ? <p style={{ marginLeft: '45%'}}>Yes</p> : <p style={{ marginLeft: '45%',padding: '5px', backgroundColor: "#ff4761",color:"white"}}>No</p> 
            ) 
        },
        {
            Header: 'SPAPI Auth ',
            accessor: 'spapi_auth',
            Cell: (props) => (
                props.original.spapi_auth ? <p style={{ marginLeft: '45%'}}>Yes</p> : <p style={{ marginLeft: '40%',padding: '5px', backgroundColor: "#ff4761",color:"white"}}>No</p> 
            )
        },
        {
            Header: 'Manage',
            accessor: 'isManaged',
            Cell: props => (     
                props.original.isManaged ?      
                <Fragment>                    
                    <Button
                        className="m-auto"
                        color="link"
                        onClick={() => { 
                            handleUpdateStoreManagementStatus(props.original.integerID,props.value);  
                        }}
                    >
                        Pause 
                    </Button>                 
                </Fragment>
                :
                <Fragment>                    
                <Button
                    className="m-auto"
                    color="link"
                    onClick={() => {  
                        handleUpdateStoreManagementStatus(props.original.integerID,props.value); 
                    }}
                >
                    Start 
                </Button>                 
            </Fragment>

            ) 
        }, 
        {
            Header: 'Visibility',
            accessor: 'isVisible',
            Cell: (props) => (
                props.original.isVisible ?  
                <Button
                className="m-auto"
                color="link" onClick={()=>{changeVisibility(props.original.isVisible,props.original.integerID)}}>Visible </Button>  
                : 
                <Button
                className="m-auto"
                color="link" onClick={()=>{changeVisibility(props.original.isVisible,props.original.integerID)}}>In Visible</Button>
            )
        },
        {
            Header: 'Users',
            accessor: 'integerID',
            Cell: props => (           
                <Fragment>                    
                    <Button
                        className="m-auto"
                        color="link"
                        onClick={() => {                                                       
                            setIsStoresOpen(!isStoresOpen)
                            handleFetchUsersForStores(props.original.integerID,props.original.name);                                                         //props.requestStores(selectedUser.email);
                          
                        }}
                    >
                        View 
                    </Button>                 
                </Fragment>
            )
        },     
        {
            Header: 'Products',
            width: 100,
            Cell: (props) => productQuickActionsDropdown(props.original),
            filterable: false,
        },
        {
            Header: 'Reports',
            width: 80,
            Cell: props => quickActionsDropdown(props.original),
            filterable: false,
        },    
        // {
        //     Header: 'Reports',
        //     accessor: 'integerID',
           
        //     Cell: props => (           
        //         <Fragment>                    
        //             <Button
        //                 className="ml-auto"
        //                 color="link"
        //                 onClick={() => {                            
                           
        //                     handleRequestReports(props.original.integerID);                                             //props.requestStores(selectedUser.email);
                          
        //                 }}
        //             >
        //                 Request 
        //             </Button>          
        //             <Button
        //                 className="ml-auto"
        //                 color="link"
        //                 onClick={() => {                            
                           
        //                     handleSaveReports(props.original.integerID);                                               //props.requestStores(selectedUser.email);
                          
        //                 }}
        //             >
        //                 Save 
        //             </Button>     
                          
        //         </Fragment>
        //     )
        // }  ,    
       
        // {
        //     Header: 'Error',
        //     accessor: 'integerID',
           
        //     Cell: props => (           
        //         <Fragment> 
        //             <Button
        //                 className="m-auto"
        //                 color="link"
        //                 onClick={() => {                            
                           
        //                     RetryErrorReportV3(props.original.integerID);                                               //props.requestStores(selectedUser.email);
                          
        //                 }}
        //             >
        //                 Retry 
        //             </Button> 
        //               </Fragment>
        //     )
        // }  ,      

        {
            Header: 'Amazon Link',
            accessor: 'store_link',
            Cell: props =><a 
                            className="m-auto"         
                            href={props.value}
                            target="_blank"
                            rel="noopener noreferrer"
                        > 
                            Click here 
                        </a>
            
        }, 
        // {
        //     Header: 'location',
        //     accessor: 'storeUsers',
        //     Cell: props => `${props.original.storeUsers.length} user(s)`
        // }
    ];

    const innerColumns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
    ];
    const subInnerColumns = [
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        {
            Header: 'Name',
            accessor: 'productTitle',
        },      
        {
            Header: 'Marketplace',
            accessor: 'location',
        },       
        {
            Header: 'Price',
            accessor: 'itemPrice',
        },
        {
            Header: 'Rating',
            accessor: 'itemRating',
        },
        {
            Header: 'Is Managed',
            accessor: 'simpliworksManagementState',
        }, 
        {
            Header: 'Actions',
            accessor: 'asin',
            Cell: props => (
                <Fragment>                  
                    <Button
                        className=""  
                        color="link"
                        onClick={() => {
                            handleRemoveProduct(props.value,props.original.integerID);
                        }}
                    >
                        Delete
                    </Button>
                </Fragment>
            )
        },
        {
            Header: 'Skus',
            accessor: 'skus',        
        },
        {
            Header: 'Skus',
            accessor: 'skus',
            Cell: props => (           
                <Fragment>                    
                    <Button
                        className="m-auto"
                        color="link"
                        onClick={() => {                                                       
                           
                            handleUpdateSkus(props.original.asin,props.original.IntegerId);                                                         //props.requestStores(selectedUser.email);
                          
                        }}
                    >
                        Update 
                    </Button>                 
                </Fragment>
            )
        }, 
        {
            Header: 'Link',
            accessor: 'link',
            Cell: props =><a          
                            href={props.value}
                            target="_blank"
                            rel="noopener noreferrer"
                        > 
                            Click here 
                        </a>
            
        }, 
    ];
    const historyColumns = [
        {
            Header: 'IntegerId',
            accessor: 'integerId',
        },
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        // {
        //     Header:'Is Managed',
        //     accessor: 'isManaged',
        //     Cell: props => (     
        //         props.original.isManaged ? <p style={{ marginLeft: '45%'}}>Yes</p> : <p style={{ marginLeft: '45%',padding: '5px',backgroundColor: "#ff4761",color:"white"}}>No</p> 
        //     ) 
        // },
        {
            Header:'Management State',
            accessor: 'simpliworksManagementState',
        },
        {
            Header:'Date',
            accessor: 'createdAt',
        },
        {
            Header:'Updated By',
            accessor: 'createdBy',
        },
        {
            Header:'Title',
            accessor: 'productTitle',
        }
    ]
    const [query, setQuery] = useState("");
    const [searchByIntId, setSearchByIntId] = useState("");
    
    const search = (data) => {
        return stores.filter((item) => {
            const nameMatches = item.name.toLowerCase().includes(query.toLowerCase());
            const intIdMatches = item.integerID.toString().includes(searchByIntId.toString());
            return nameMatches || intIdMatches;
        });
    };
    

    return (
        <Fragment>
        <div>
        <Row>
        <CustomModal
                isOpen={isStoresOpen}
                toggle={() => setIsStoresOpen(!isStoresOpen)}
                header={`${selectedStore} : Users List`}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresOpen(!isStoresOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <ReactTable
                        data={storeUsers}
                        columns={innerColumns}      
                        defaultPageSize={5}                
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: 'hidden'
                                }
                            }
                        }}
                        className="-striped -highlight -fixed"
                        noDataText={"No users found."}
                    />
                </Card>

                
            </CustomModal>

        <Col md="2" className="mb-2">
        
            <Input 
                id="inputValue"
                // style="width:80%"
                style={{width: "100%"}}
                placeholder='search text'
                className="Search"
                onChange={event => {
                    setQuery(event.target.value);
                    setSearchByIntId(event.target.value);}}
            />
           
            </Col>
            <Col md="1" className="mb-2">
                 <Button
                     className="mb-5 pl-4 pr-4 text-center"
                     color="brand-blue-dark"
                     onClick={() => setStores(props.allStoresData)}  
                 >
                     All
                 </Button>
            </Col>
            <Col md="1" className="mb-2">
                <Button
                     className="mb-3"
                     color="brand-blue-dark"
                     onClick={() => setStores(props.managedStoreData)}  
                 >
                     Managed
                </Button>
            </Col>
            <Col md="1" className="mb-3 ml-3 mr-2">
                <Button
                     className="mb-3"
                     color="brand-blue-dark"
                     onClick={() => setStores(props.unmanagedStoreData)}  
                 >
                     Unmanaged
                </Button>
            </Col>
            <Col md="1" className="mb-3 ml-4 d-none">
                <Button
                     className="mb-3"
                     color="brand-blue-dark"
                     onClick={() => setStores(props.unmanagedStoreData)}  
                 >
                     IsVisible
                </Button>
            </Col>
        </Row>
    </div>
        <ReactTable
            data={search(stores)}
            columns={outerColumns}
            defaultPageSize={10}
            noDataText={"No stores found."}
            style={{
                height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"           
        />
        <CustomModal
                isOpen={isStoresAsinOpen}
                toggle={() => setIsStoresAsinOpen(!isStoresAsinOpen)}
                header={`Amazon Stores/Asin for : ${selectedStore} `}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresAsinOpen(!isStoresAsinOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <div className="p-3">

            <div>
                <h3>Products</h3>
            </div>
            <ReactTable
                data={managedProducts}
                columns={subInnerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />
        </div>
                </Card>
            </CustomModal>
            <CustomModal
                isOpen={requestDaysPopup}
                toggle={() => setRequestDaysPopup(!requestDaysPopup)}
                header={`Request Adv Report`}
                size={"sm"}
                className='card-of-problemarea bgColor '
            >
             <Card className='p-0 card-of-problemarea  ' >
            <Container >
              <div className=" p-0">
            <div className="">
                <div className="text-center mx-auto mb-3">
                </div>
               
                <Form>
                    <Row form className='pt-1 pl-2 pr-2 pb-3'>
                        <Col md={12}>
                        <Label>
                        <div className="d-flex justify-content-center"><h6 className="p-2">Enter Days :</h6> <input className="form-control w-25 ml-3" onChange={(e)=>setDays(e.target.value)} /> </div>
                      <p className="text-center  mb-0 mt-3"><Button className="btn_class" onClick={()=>handleRequestReports(days,integerID)}>Submit</Button></p>
                      </Label> 
                        </Col>
                       
                    </Row>
                </Form>
            </div>
        </div>
            </Container>
          </Card>
            </CustomModal>
            <CustomModal
                isOpen={SPAPIReportsPopup}
                toggle={() => setSPAPIReportsPopup(!SPAPIReportsPopup)}
                header={`Fetch SPAPI Asin Report`}
                size={"sm"}
                className='card-of-problemarea bgColor '
            >
             <Card className='p-0 card-of-problemarea  ' >
            <Container >
              <div className=" p-0">
            <div className="">
                <Form>
                    <Row form className='pt-1 pl-2 pr-2 pb-3'>
                        <Col md={12}>
                        <Label>
                        <div className="d-flex justify-content-center"><h6 className="p-2">Enter Days :</h6> <input className="form-control w-25 ml-3" onChange={(e)=>setDays(e.target.value)} /> </div>
                      <p className="text-center  mb-0 mt-3"><Button className="btn_class" onClick={()=>fetchSPAPIReports()}>Submit</Button></p>
                      </Label> 
                        </Col>
                       
                    </Row>
                </Form>
            </div>
        </div>
            </Container>
          </Card>
            </CustomModal>
            <CustomModal
                isOpen={SPAPIStoresReportsPopup}
                toggle={() => setSPAPIStoresReportsPopup(!SPAPIStoresReportsPopup)}
                header={`Fetch SPAPI Store Report`}
                size={"sm"}
                className='card-of-problemarea bgColor '
            >
             <Card className='p-0 card-of-problemarea  ' >
            <Container >
              <div className=" p-0">
            <div className="">
                <Form>
                    <Row form className='pt-1 pl-2 pr-2 pb-3'>
                        <Col md={12}>
                        <Label>
                        <div className="d-flex justify-content-center"><h6 className="p-2">Enter Days :</h6> <input className="form-control w-25 ml-3" onChange={(e)=>SetStoreDays(e.target.value)} /> </div>
                      <p className="text-center  mb-0 mt-3"><Button className="btn_class" onClick={()=>fetchSPAPIStoreReports()}>Submit</Button></p>
                      </Label> 
                        </Col>
                       
                    </Row>
                </Form>
            </div>
        </div>
            </Container>
          </Card>
            </CustomModal>
            
            <CustomModal 
                header={"Report List"}
                isOpen={errorReportlistOpen}
                toggle={() => setErrorReportlistOpen(!errorReportlistOpen)}
                size={"xl"}
                className='curve-popup'
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setErrorReportlistOpen(!errorReportlistOpen)}
                            >
                            Cancel
                        </Button>
                    </Fragment>
                }        
                    
            >  

            <ReactTable
            data={errorReports}
            columns={columns}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped"                                
            />
 
             </CustomModal>

            <CustomModal 
                header={"Report status"}
                isOpen={isJsonOpen}
                toggle={() => setIsJsonOpen(!isJsonOpen)}
                size={"xl"}
                className='curve-popup'
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setIsJsonOpen(!isJsonOpen)}
                            >
                            Cancel
                        </Button>
                    </Fragment>
                }        
                    
            >  

                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{jsonData}</pre> </p></Label> 
 
             </CustomModal>
             <CustomModal 
                header={"All Product Management History"}
                isOpen={managementHistoryOpen}
                toggle={() => setManagementHistoryOpen(!managementHistoryOpen)}
                size={"xl"}
                className='curve-popup'
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setManagementHistoryOpen(!managementHistoryOpen)}
                            >
                            Cancel
                        </Button>
                    </Fragment>
                }        
            >  
                <Card className='curve-popup' >
                    <Row className='p-4'>
                        <Col md={12} className=' d-flex mb-3 justify-content-end'>
                        <Button
                        className ='btn btn-brand-blue-dark'
                        onClick = {()=>exportProductManagementHistoryCSV(integerID)}>
                            Download
                        </Button>
                        </Col>
                        <Col md={12}>
                            <ReactTable
                                data={managementHistory}
                                columns={historyColumns}
                                noDataText={"No Data Found."}
                                style={{height: "500px"}}
                                className="-fixed -highlight -striped"                                
                            />
                        </Col>
                    </Row>
                </Card>
            </CustomModal>
    </Fragment>
    )
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 



export default connect(mapStateToProps)(StoresDirectoryTable)