import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap";
import { DropdownList } from "react-widgets";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import CreateNewTicketService from "../../../../../Service/DashboardServices/TroubleTicketServices/CreateNewTicketService";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from "../../../../../Utils/Constants"; 
const DropdownWithCheckbox = ({ options, onSelect }) => {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleSelect = () => {
    onSelect(selectedValues);
    setOpen(false);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "5px",
          cursor: "pointer",
          backgroundColor: "white",
        }}
        onClick={() => setOpen(!open)}
      >
        Select SKUs
      </div>
      {open && (
        <div
          style={{
            border: "1px solid #ccc",
            borderTop: "none",
            backgroundColor: "white",
          }}
        >
          {options.map((option) => (
            <label
              key={option}
              style={{ display: "block", padding: "5px 10px" }}
            >
              <input
                type="checkbox"
                checked={selectedValues.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              &nbsp;
              {option}
            </label>
          ))}
          <p className="text-center p-0">
            <button className="btn btn-primary" onClick={handleSelect}>
              Apply
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

function CreateTicket(props) {
  const [checked, setChecked] = useState(false);
  const [SKUsList, setSKUsList] = useState();
  const [asinDisplayCondition, setAsinDisplayCondition] = useState(false);
  const [query, setQuery] = useState("");
  const [isInternalTicket, setIsInternalTicket] = useState(
    props.accessLevel == 0 ? true : false
  );

  const [subRequestTypes, setSubRequestTypes] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [asinArray, setAsinArray] = useState([]);
  const [formData, setFormData] = useState({
    ticketTitle: "",
    mainRequestType: props.requestTypeID ? props.requestTypeID : "",
    subRequestType: props.requestSubTypeID ? props.requestSubTypeID : "",
    product: props.asinForTicketList ? props.asinForTicketList : "",
    integerId: props.storeOverview.integerID,
    storeName: props.storeOverview.name,
    sku: "",
    ticketHistoryComment: "",
    problemAreaId: props.problemAreaId ? props.problemAreaId : "",
    problemAreaTitle: "",
    problemAreaPathwayId: props.problemAreaPathwayId
      ? props.problemAreaPathwayId
      : "",
    problemAreaPathwayTitle: "",
    isInternalTicket: isInternalTicket,
    amazonCaseUrl:'',
    amazonCaseId:''
  });
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [errors, setErrors] = useState({ amazonCaseUrl: '' });
  const [isValidUrl, setIsValidUrl] = useState(false);
  const handleSelect = (values, productId) => {
    setSelectedValues({ ...selectedValues, [productId]: values });
    console.log(selectedValues);
    const convertedArray2 = Object.keys(selectedValues).map((key) => ({
      key,
      value: selectedValues[key],
    }));
    console.log("Converted array using Object.entries:", convertedArray2);
    setFormData({ ...formData, ["sku"]: selectedValues });
  };
  function handleChange(e) {
    setChecked(e.target.checked);
    const value = e.target.value;
    if (e.target.checked === false) {
      const updatedArray = [...asinArray];
      updatedArray.pop(value);
      setAsinArray(updatedArray);
      setFormData({ ...formData, ["product"]: updatedArray });
      // console.log(asinArray);
    } else if (e.target.checked === true) {
      const updatedArray = [...asinArray];
      updatedArray.push(value);
      setAsinArray(updatedArray);
      setFormData({ ...formData, ["product"]: updatedArray });
    }
  }

  useEffect(() => {
    if (props.subRequestTypes) {
      handleSelectSubRequestType("mainRequestType", props.problemAreaPathwayId);
    }
    if (props.problemAreaId) {
      props.problemAreaList.map((pa) => {
        if (props.problemAreaId == pa.id) {
          setFormData({ ...formData, ["problemAreaTitle"]: pa.problemArea });
        }
      });
    }
    if (props.problemAreaPathwayId) {
      props.problemAreaPathwayList.map((pap) => {
        if (props.problemAreaPathwayId == pap.id) {
          setFormData({
            ...formData,
            ["problemAreaPathwayTitle"]: pap.problemAreaPathway,
          });
        }
      });
    }
  }, []);
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };
  const validateUrl = (url) => {
    const urlPattern = /^(https?:\/\/)/i;
    return urlPattern.test(url);
  };
  const [numericIdMsg, setNumericIdMsg]=useState(false)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mainRequestType") {
      handleSelectSubRequestType(name, value);
      setFormData({ ...formData, [name]: value });
    }
    else if (name === "isInternalTicket") {
      setFormData({ ...formData, [name]: e.target.checked });
    } else {
      let newErrors = { ...errors };
      if (name === 'amazonCaseUrl') {
        const isValid = validateUrl(value);
        setIsValidUrl(isValid);
        if (!isValid && value.length > 0) {
          setFormData({ ...formData, [name]: value });
          newErrors[name] = 'Please enter a valid URL starting with http:// or https://';
          
        } else {
          setFormData({ ...formData, [name]: value });
          newErrors[name] = '';
        }
      }
      else if (name === 'amazonCaseId') {
        if (/^\d*$/.test(value)) {
          setFormData({ ...formData, [name]: value });
          newErrors[name] = '';
          setNumericIdMsg(false)
        }
        else{
          return setNumericIdMsg('Please enter only numbers')
        }
      }
      else{
        setFormData({ ...formData, [name]: value });
        setErrors(newErrors);
      }
      // setFormData({ ...formData, [name]: value });
      setErrors(newErrors);
    }
  };
  const handleSubmit = () => {
    // e.preventDefault();
    setLoading(true)
    const newFormData = new FormData();
    console.log("Form Data Submitted:", formData);
    newFormData.append("ticketTitle", formData.ticketTitle);
    newFormData.append("amazonCaseId", formData.amazonCaseId);
    newFormData.append("amazonCaseUrl", formData.amazonCaseUrl);
    newFormData.append("mainRequestType", formData.mainRequestType);
    newFormData.append("problemAreaId", formData.problemAreaId);
    newFormData.append("problemAreaPathwayId", formData.problemAreaPathwayId);
    newFormData.append("subRequestType", formData.subRequestType);
    newFormData.append("asin", formData.product);
    newFormData.append("sku", formData.sku);
    newFormData.append("integerId", formData.integerId);
    newFormData.append("storeName", formData.storeName);
    newFormData.append("isInternalTicket", formData.isInternalTicket);
    newFormData.append("ticketHistoryComment", formData.ticketHistoryComment);

    for (let i = 0; i < selectedFiles.length; i++) {
      newFormData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    console.log(newFormData);
    // return true
    // setTimeout(() => {
    //   setLoading(false);
    //   return true
    //   }, 5000);
    CreateNewTicketService(props.token, newFormData).then((response) => {
      if (response) {
        props.handleCreateAndViewTicketClose(response);
        setLoading(false);
      }
    });
  };
  const handleSelectSKUs = (name, productID) => {
    props.storeProducts.map((product) => {
      if (product.asin == productID) {
        setSKUsList(product.skus);
      }
    });
  };
  const handleSelectSubRequestType = (name, id) => {
    const filteredSubRequestTypes = props.subRequestTypes.filter(
      (subType) => subType.problemAreaPathwayId == id
    );
    filteredSubRequestTypes.map((type) => {
      if (type.id == props.requestSubTypeID) {
        setAsinDisplayCondition(type.asinMandatory);
      }
    });
    setSubRequestTypes(filteredSubRequestTypes);
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const filteredProductList = props.storeProducts.filter((row) => {
    const asin = row.asin.toLowerCase();
    const productTitle = row.productTitle.toLowerCase();

    if (row.skus != null) {
      const skus = row.skus.join("").toLowerCase();
      if (query !== "") {
        return (
          asin.includes(query.toLowerCase()) ||
          productTitle.includes(query.toLowerCase()) ||
          skus.includes(query.toLowerCase())
        );
      }
    }
    if (query !== "") {
      return (
        asin.includes(query.toLowerCase()) ||
        productTitle.includes(query.toLowerCase())
      );
    } else {
      return true; // Show all
    }
  });
  const handleQuillChange = (value) => {
    setFormData({ ...formData, ticketHistoryComment: value });
    console.log(formData)
  };
  return (
    <Container fluid className="mt-4">
      <Row className="m-0 justify-content-center">
        <Col md="12" className="mbg-3 font-size-lg p-0">
          <Card className="main-card card-of-problemarea">
            <CardBody>
              {/* <CardTitle>Create Ticket</CardTitle> */}
              <Fragment>
                {/* <Row className="divider" /> */}
                <div>
                  <form>
                    <Row form className="justify-content-center">
                        {/* {props.problemAreaId == 1?<>
                        <Col md={6}>
                          <div>
                          <input
                                type="text"
                                id="amazonCaseId"
                                name="amazonCaseId"
                                value={formData.amazonCaseId}
                                // className="form-control mb-2"
                                className={`form-control mb-2 ${formData.amazonCaseUrl && formData.amazonCaseId == '' ? 'is-invalid' : ''}`}
                                placeholder='Amazon Case ID'
                                onChange={handleInputChange}
                              />
                              <p className="text-danger">{numericIdMsg}</p>
                          </div>
                        </Col>
                        <Col md={6}>
                        <div>
                          <input
                            type="text"
                            id="amazonCaseUrl"
                            name="amazonCaseUrl"
                            value={formData.amazonCaseUrl}
                            // className="form-control mb-2"
                            className={`form-control mb-2 ${formData.amazonCaseId && formData.amazonCaseUrl == ''? 'is-invalid' : ''}`}
                            placeholder="Amazon Case URL"
                            onChange={handleInputChange}
                          />
                          {errors.amazonCaseUrl && <p className="text-danger">{errors.amazonCaseUrl}</p>}
                        </div>
                        </Col>
                        </>:''} */}
                      
                      <Col md={12}>
                        {/* <div className="d-flex">
                          <span className="text-danger"><b>*</b></span>
                          &nbsp;
                          <textarea
                            id="ticketHistoryComment"
                            name="ticketHistoryComment"
                            rows={6}
                            value={formData.ticketHistoryComment}
                            className="form-control"
                            onChange={handleInputChange}
                            placeholder="Please describe your problem"
                          ></textarea>
                        </div> */}
                         <ReactQuill
                          theme={'snow'}
                          modules={Editor.modules}
                          formats={Editor.formats}
                          value={formData.ticketHistoryComment}
                          onChange={handleQuillChange}
                          bounds={'.quill-prod'}
                          placeholder='Add Your Comments Here' />
                        </Col>
                      <Col md={6} className='mt-2'>
                              <label
                                htmlFor="fileInput"
                                className="custom-file-input-label text-center"
                              >
                                <i className="fas fa-upload"></i>
                                <br /> Choose File
                              </label>
                              <input
                                type="file"
                                id="fileInput"
                                name="ticketAttachment"
                                className="custom-file-input"
                                onChange={handleFileChange}
                              />
                              </Col>
                              <Col md={6} className='mt-2'>
                              <span style={{ fontSize: "13px" }}>
                                Attached file List
                              </span>
                              <ul style={{ color: "green", fontSize: "13px" }}>
                                {selectedFiles
                                  ? selectedFiles.map((file, index) => (
                                      <li key={index}>
                                        {file.name}
                                        <button
                                          className="btn"
                                          onClick={() => handleRemoveFile(file)}
                                        >
                                          <FontAwesomeIcon
                                            color="red"
                                            size="15px"
                                            icon={faWindowClose}
                                          />
                                        </button>
                                      </li>
                                    ))
                                  : ""}
                              </ul>
                            </Col>
                            {props.accessLevel === 0 ? (
                              <Col md={12} className="ml-5"></Col>
                            ) : (
                              ""
                            )}
                    

                      <Col
                        md={12}
                        className={
                          subRequestTypes
                            ? subRequestTypes.map((type) =>
                                type.id == formData.subRequestType
                                  ? type.asinDisplay
                                    ? ""
                                    : " d-none"
                                  : ""
                              )
                            : ""
                        }
                      >
                        <Row className="mb-2 mt-2">
                          <Col md={6}>
                            <label htmlFor="subRequestType">
                              <b>
                                Product(s)
                                <span
                                  className={
                                    subRequestTypes
                                      ? subRequestTypes.map((type) =>
                                          type.id == formData.subRequestType
                                            ? type.asinMandatory
                                              ? ""
                                              : " d-none"
                                            : ""
                                        )
                                      : ""
                                  }
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>{" "}
                                :
                              </b>
                            </label>
                          </Col>
                          <Col md={6}>
                            <span
                              className={
                                filteredProductList.length > 0 ? "" : "d-none"
                              }
                            >
                              <input
                                className="form-control float-right"
                                type="text"
                                placeholder="Search product by Title, ASIN or SKU"
                                onChange={(e) => setQuery(e.target.value)}
                              />
                            </span>
                          </Col>
                        </Row>
                        <div
                          className={
                            filteredProductList.length > 0 ? "scrollbar " : " "
                          }
                        >
                          {filteredProductList.length > 0 ? (
                            filteredProductList.map((product) =>
                              props.asinForTicketList == product.asin ? (
                                <>
                                  <Row className="m-0 p-0">
                                    <Col md="1" className="mt-2 p-0">
                                      <div className="">
                                        <input
                                          className="mt-5 ml-4  "
                                          value={product.asin}
                                          type="checkbox"
                                          name={product.asin}
                                          onChange={handleChange}
                                          checked={
                                            props.asinForTicketList == product.asin ? true : false
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      md="11"
                                      className="border row-background-color"
                                    >
                                      <Row className="justify-content-center ">
                                        <Col md="2" className="p-2">
                                          <div>
                                            <img
                                              className="ml-0"
                                              width={"100%"}
                                              src={product.itemImage}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="10" className="p-2">
                                          <div>
                                            <h6>
                                              <b>{product.productTitle}</b>
                                            </h6>
                                            <div className="">
                                              <Row>
                                                <Col md={5}>
                                                  <span>
                                                    <b>ASIN : </b>{" "}
                                                    {product.asin}
                                                  </span>
                                                </Col>
                                                <Col md={7}>
                                                  {/* <div>
                                                <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                              </div> */}
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : props.asinForTicketList == "" ? (
                                <>
                                  <Row className="m-0 p-0">
                                    <Col md="1" className="mt-2 p-0">
                                      <div className="">
                                        <input
                                          className="mt-5 ml-4  "
                                          value={product.asin}
                                          type="checkbox"
                                          name={product.asin}
                                          onChange={handleChange}
                                          checked={asinArray.includes(product.asin) ? true : false}
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      md="11"
                                      className="border row-background-color"
                                    >
                                      <Row className="justify-content-center ">
                                        <Col md="2" className="p-2">
                                          <div>
                                            <img
                                              className="ml-0"
                                              width={"100%"}
                                              src={product.itemImage}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="10" className="p-2">
                                          <div>
                                            <h6>
                                              <b>{product.productTitle}</b>
                                            </h6>
                                            <div className="">
                                              <Row>
                                                <Col md={5}>
                                                  <span>
                                                    <b>ASIN : </b>{" "}
                                                    {product.asin}
                                                  </span>
                                                </Col>
                                                <Col md={7}>
                                                  {/* <div>
                                                <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                              </div> */}
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ""
                              )
                            )
                          ) : (
                            <p className="text-center">
                              {" "}
                              No product available.
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {asinDisplayCondition ? (
                      <p className=" mt-3 text-center">
                        {props.accessLevel == 0 ?
                        <Label>
                        <Input
                          type="checkbox"
                          name="isInternalTicket"
                          checked={isInternalTicket}
                          onChange={(e) => {
                            setIsInternalTicket(!isInternalTicket);
                            handleInputChange(e);
                          }}
                        />
                        <span>
                          <b> Is it an internal ticket?</b>
                        </span>
                      </Label>:''}
                        <Button
                          className="btn_class"
                          // disabled={!(formData.ticketHistoryComment && formData.product.length > 0 && asinDisplayCondition && !(!isValidUrl && formData.amazonCaseUrl.length >0))? true :( loading ? true : false) }
                          onClick={()=>handleSubmit()}
                          disabled={!(formData.ticketHistoryComment && formData.product.length > 0 && asinDisplayCondition)? true :( loading ? true : false) }
                        >
                          {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                      </p>
                    ) : (
                      <p className=" mt-3 text-center">
                         {props.accessLevel == 0 ?(
                          <Label>
                          <Input
                            type="checkbox"
                            name="isInternalTicket"
                            checked={isInternalTicket}
                            onChange={(e) => {
                              setIsInternalTicket(!isInternalTicket);
                              handleInputChange(e);
                            }}
                          />
                          <span>
                            <b> Is it an internal ticket?</b>
                          </span>
    

                        </Label>):""}

                        <Button
                          className="btn_class"
                          // disabled={!(formData.ticketHistoryComment  && !(!isValidUrl && formData.amazonCaseUrl.length >0) ? true :( loading ? true : false))}
                          onClick={()=>handleSubmit()}
                          disabled={!(formData.ticketHistoryComment) ? true :loading ? true : false}
                       >
                        {loading ? 'Submitting...' : 'Submit'}

                        </Button>
                      </p>
                    )}
                  </form>
                </div>
              </Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(CreateTicket);
