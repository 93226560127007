import axios from 'axios';
import { LoginUser, LoginFailed } from "../../Redux/Actions/Actions";
import actionType from "../../Redux/Actions/ActionTypes";
import { CURRENT_API_URL } from "../../Utils/Constants";


function LoginService(user) {
    return function(dispatch) {

        dispatch({
            type: actionType.LOGIN_BEGIN,
            newUser: false,
        });

        axios.post(`${CURRENT_API_URL}/account/login`, user)
            .then(res => {
                console.log(res.data);
                if (res.status === 200) {
                    if (res.data.profiles) {
                        dispatch(LoginUser(
                            res.data.user,
                            res.data.token,
                            res.data.accessLevel,
                            res.data.profiles,
                            res.data.needsAdvertisingAPIAuth,
                            res.data.subscriptionLevel,
                            res.data.appSumoPlanId,
                            res.data.hasCampaigns,
                        ));
                    } else {
                        dispatch(LoginUser(
                            res.data.user,
                            res.data.token,
                            res.data.accessLevel,
                            [],
                            res.data.needsAdvertisingAPIAuth,
                            res.data.subscriptionLevel,
                            res.data.appSumoPlanId,
                            res.data.hasCampaigns,
                        ));
                    }
                }
            })
            .catch(error => {
                    if (error.response) {
                        dispatch(LoginFailed("Invalid e-mail address or password."));
                    } else if (error.request) {
                        dispatch(LoginFailed("We are having trouble connecting to the server. Please try again later."));
                    } else {
                        dispatch(LoginFailed("We're sorry, but we're not sure what went wrong. Please try again later."));
                    }
                }
            );
    }

}

export default LoginService;
