import React from "react";
import { connect } from 'react-redux';

import { AmznLogin, FinishAmznLogout } from "../../../../Redux/Actions/Actions";
import LoginButton from "./SocialButton";
import { LWA_CLIENT_ID } from "../../../../Utils/Constants";


class LWAButton extends React.Component {

    constructor(props) {
        super(props)

        this.nodes = {}

        this.onLoginSuccess = this.onLoginSuccess.bind(this)
        this.onLoginFailure = this.onLoginFailure.bind(this)
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this)
        this.onLogoutFailure = this.onLogoutFailure.bind(this)
    }

    setNodeRef (provider, node) {
        if (node) {
            this.nodes[ provider ] = node
        }
    }

    onLoginSuccess (user) {
        this.props.dispatch(AmznLogin(user));
    }

    onLoginFailure (err) {
        console.error(err);
        this.props.dispatch(FinishAmznLogout());
    }

    onLogoutSuccess () {
        this.props.dispatch(FinishAmznLogout());
    }

    onLogoutFailure (err) {
        console.error(err);
    }

    render() {

        // call sdk to logout from amazon upon TriggerAmznLogout dispatch following successful user registration
        if (!this.props.isAuth) {
            const { provider } = this.props;
            if (provider) {
                this.nodes[provider].props.triggerLogout();
            }
        }
        return (
            <LoginButton
                provider='amazon'
                appId={LWA_CLIENT_ID}
                onLoginSuccess={this.onLoginSuccess}
                onLoginFailure={this.onLoginFailure}
                onLogoutFailure={this.onLogoutFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                getInstance={this.setNodeRef.bind(this, 'amazon')}
                key={'amazon'}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.LWA.user,
        provider: state.LWA.provider,
        isAuth: state.LWA.isAuth,
    }
}

export default connect(mapStateToProps)(LWAButton);
